import React, { useState, useEffect, useCallback } from "react";
import Image from "next/image";

const CustomImage = ({ src, alt, ...props }) => {
  const [imgSrc, setImgSrc] = useState(src);

  const handleError = useCallback(() => {
    setImgSrc("/images/blog/default.jpg");
  }, []);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return <Image src={imgSrc} alt={alt} onError={handleError} {...props} />;
};

export default React.memo(CustomImage);
