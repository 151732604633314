"use client";
import React, { useEffect, useState } from "react";
import { getLatestBlogs } from "@/lib/mdxBlogs";
import Link from "next/link";
import Image from "next/image";
import CustomImage from "@/components/CustomImage";

const LatestBlog = ({ category = "" }) => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const fetchedBlogs = await getLatestBlogs({ category });
        setBlogs(fetchedBlogs);
      } catch (error) {
        console.error("Failed to fetch the latest blogs", error);
        setBlogs([]);
      }
    };

    fetchBlogs();
  }, [category]);

  if (blogs && blogs?.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <section className="py-8 md:py-10 lg:py-12 relative">
      <div className="max-w-screen-xl mx-5 lg:mx-auto">
        <h2 className="text-center py-8">Latest Blog</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 items-start justify-between text-center">
          {blogs.map((blog) => (
            <div
              key={blog.id}
              className="bg-white shadow-lg border-b-4 border-b-orange-500 h-full"
            >
              <div className="relative h-56 w-full">
                <CustomImage
                  src={blog.featuredImage || "/images/blog/default.jpg"}
                  alt={blog.title}
                  style={{
                    width: "100%",
                    height: "100%",
                    transition: "transform 1s ease-in-out object-cover",
                    hover: { transform: "scale(1.1)" },
                  }}
                  width={400}
                  height={225}
                />
              </div>
              <div>
                <h3 className="font-bold mb-0 mt-4 text-xl px-4">
                  {blog.title}
                </h3>
                <h4 className="mb-0">
                  {new Date(blog.date).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </h4>
              </div>

              <div className="mt-2 md:mt-3 flex flex-col items-center justify-center">
                <Link href={`/blog/${blog.slug}`} passHref>
                  <p className="text-sm bg-white hover:bg-orange-600 border border-orange-600 text-orange-600 hover:text-white font-semibold rounded-md uppercase px-2 py-2 lg:px-4">
                    View Details
                  </p>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center mt-10">
        <Link href="/blog">
          <p className=" hover:bg-orange-600 border border-orange-600 bg-orange-500 text-black hover:text-white font-semibold rounded-md uppercase px-6 py-1 lg:px-8 lg:py-2 text-sm w-48 text-center">
            More Blogs
          </p>
        </Link>
      </div>
    </section>
  );
};

export default LatestBlog;
