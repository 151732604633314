"use client";
import React from "react";
import CheckIcon from "@/components/CheckIcon";
import {
  childVariants,
  itemVariants,
  parentVariants,
} from "../constants/motion";
import { motion } from "framer-motion";

const USP = ({ features = [] }) => {
  return (
    <motion.section
      variants={parentVariants}
      initial="initial"
      whileInView="animate"
      className="bg-color-brown-200 py-10 md:py-20"
    >
      <div className="max-w-screen-xl mx-5 lg:mx-auto flex-1">
        <motion.h2
          variants={childVariants}
          className="text-center font-bold mb-4 md:mb-10 text-color-blue-800"
        >
          Our Key Features
        </motion.h2>
        <ul className="list-none grid grid-cols-2 md:grid-cols-6 justify-center gap-x-10 gap-y-2 items-center">
          {features.map((feature, index) => (
            <motion.li
              key={index}
              variants={itemVariants.left}
              className="self-start justify-center text-center flex-1"
            >
              <div className="rounded-full border-[4px] md:border-[8px] border-color-brown-300 w-24 h-24 md:h-36 md:w-36 flex items-center justify-center bg-color-brown-100 mx-auto">
                <CheckIcon className="text-green-500 text-8xl w-16 h-16 md:w-24 md:h-24" />
              </div>
              <div className="mt-3 font-semibold text-sm md:text-lg">
                {feature}
              </div>
            </motion.li>
          ))}
        </ul>
      </div>
    </motion.section>
  );
};

export default USP;
