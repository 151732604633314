"use client";
import { motion } from "framer-motion";
import Image from "next/image";
import React, { memo } from "react";
import { childVariants, parentVariants } from "../constants/motion";

const Benefits = ({ title, children, blockquotes, image }) => {
  return (
    <motion.section
      variants={parentVariants}
      initial="initial"
      whileInView="animate"
      viewport={{ once: true }}
      className="bg-color-brown-100 py-10 md:py-14"
    >
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div>
            <div className="relative overflow-hidden rounded-3xl bg-gray-900 px-6 pb-9 pt-64 shadow-2xl sm:px-12 lg:max-w-xl lg:px-8 lg:pb-8 xl:px-10 xl:pb-10">
              <Image
                src={image.src}
                fill
                className="object-cover w-full h-full -z-20 absolute brightness-125 saturate-0"
                alt={image.alt}
                sizes="100vw"
              />
              <div className="absolute inset-0 bg-gray-900 mix-blend-multiply"></div>
              <div
                className="absolute left-1/2 top-1/2 -ml-16 -translate-x-1/2 -translate-y-1/2 transform-gpu blur-xl"
                aria-hidden="true"
              >
                <div
                  className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-40"
                  style={{
                    clipPath:
                      "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                  }}
                ></div>
              </div>
              <figure className="relative isolate">
                {/* Render blockquotes dynamically */}
                {blockquotes.map((quote, index) => (
                  <React.Fragment key={index}>
                    <motion.blockquote
                      variants={childVariants}
                      className="text-xl font-semibold leading-8 text-white"
                    >
                      <p>{quote.text}</p>
                    </motion.blockquote>
                    <motion.figcaption
                      variants={childVariants}
                      className="mt-6 text-sm leading-6 text-gray-300"
                    >
                      <strong className="font-semibold text-white">
                        {quote.author},
                      </strong>
                      {quote.position}
                    </motion.figcaption>
                    {index < blockquotes.length - 1 && (
                      <hr className="my-6 border-gray-600" />
                    )}
                  </React.Fragment>
                ))}
              </figure>
            </div>
          </div>
          <div>
            <motion.h2
              variants={childVariants}
              className="mt-2 text-3xl font-bold tracking-tight text-color-brown-800 sm:text-4xl mb-4"
            >
              {title}
            </motion.h2>
            <motion.div variants={childVariants} className="text-xl">
              {children}
            </motion.div>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default memo(Benefits);

{
  /* 
  <picture className="absolute inset-0 -z-20">
                <source media="(max-width: 768px)" srcSet={image_mobile.src} />
                <source media="(max-width: 1200px)" srcSet={image_tablet.src} />
                <Image
                  src={image.src}
                  fill
                  className="object-cover w-full h-full -z-20 absolute brightness-125 saturate-0"
                  alt={image.alt}
                  priority={true}
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 100vw, 1920px"
                  srcSet={`$${image_mobile.src} 768w, ${image.src} 1920w`}
                />
              </picture>
              */
}
